@tailwind base;
@tailwind components;
@tailwind utilities;
//
@import "../src/_css/bootstrap-4.0.0/scss/bootstrap";
@import "../src/_css/toastr-bs4-alert";
//@import "../node_modules/angular2-multiselect-dropdown/themes/default.theme.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
@import "../node_modules/angular2-multiselect-dropdown/themes/default.theme.css";
html {
   font-size: 15px;
}

@layer components {
   control-error label {
      @apply tw-text-sm tw-text-red-600;
   }
}

body {
   &, .p-component {
      font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif; //
      font-smooth: always;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
   }
}

.modal-open .modal {
   z-index: 10000000000;
}

.theme-btn {
   @apply tw-py-8 tw-py-2 tw-rounded tw-w-full tw-text-center tw-bg-teal-600 tw-text-white tw-mt-4;
}

@font-face {
   font-family: 'HindiFont';
   src: url("/assets/fonts/Kruti-Dev-010/k010.ttf") format("truetype");
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Asees';
   src: url("/assets/fonts/punjabi/ASEES.ttf") format("truetype");
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'AnmolLipi';
   src: local("AnmolLipi"), url("/assets/fonts/punjabi/AnmolLipi.ttf") format("truetype");
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'raavi';
   src: url("/assets/fonts/punjabi/raavi.ttf") format("truetype");
   font-weight: bold;
   font-style: normal;
}

@font-face {
   font-family: 'Font Awesome 5 Free';
   font-style: normal;
   font-weight: 400;
   font-display: auto;
   src: url("/assets/webfonts/fa-regular-400.eot");
   src: url("/assets/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/fa-regular-400.woff2") format("woff2"), url("/assets/webfonts/fa-regular-400.woff") format("woff"), url("/assets/webfonts/fa-regular-400.ttf") format("truetype"), url("/assets/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}

.c-label {
   padding: 10px;
}

.p-autocomplete, .p-autocomplete input {
   display: block !important;
   width: 100% !important;
}

.min-width {
   width: 5%;
   word-wrap: wrap;
}

table {
   font-size: 14px;
}

.fr-placeholder {
   margin-top: 0 !important;
}

.fr-popup {
   z-index: 3000 !important;
}

.form-group {
   label {
      span {
         color: red;
         font-weight: bold;
         font-size: 18px;
      }
   }
}

.far {
   font-family: 'Font Awesome 5 Free';
   font-weight: 400;
}

body, html {
   height: 100%;
   background-color: darken(white, 3);
}

.modal-header {
   background-color: #dcbf31;
   color: #ffffff;
}

::-webkit-scrollbar {
   background-color: #cccccc;
   width: 8px;
}

::-webkit-scrollbar-thumb {
   background-color: #e70107;
}

input.ng-invalid.ng-touched {
   border: 1px solid RED;
}

.errors {
   font-size: 13px;
   color: red;
}

.page-title {
   padding: 10px 0;
   border-bottom: 1px solid #cccccc;
   margin-bottom: 30px;

   h3 {
      margin-bottom: 0;
      font-weight: bold;
   }
}

th.min {
   width: 1%;
   white-space: normal;
}

.table-vertical-align {
   td {
      vertical-align: middle;
   }
}

.table .eva {
   font-size: 16px;
}

.big-modal {
   .modal-dialog {
      max-width: 90%;
      height: 100%;
   }
}

ngl-file-upload {
   border: 1px dotted #cccccc;
   padding: 10px;
   background-color: #c1ebff;
   width: 100%;
   -ms-text-align-last: right;
   text-align: left;
   display: block;

   svg {
      display: none;
   }

   .slds-file-selector__dropzone {
      height: 100%;
   }

   label {
      margin-bottom: 0;
   }

   .slds-button_neutral {
      background-color: #0663cc;
      padding: 10px;
      text-transform: uppercase;
      color: #ffffff;
      display: inline-block;
      margin-right: 20px;
   }

   input {
      opacity: 0;
      position: absolute;
   }
}

.loader,
.loader:after {
   border-radius: 50%;
   width: 6em;
   height: 6em;
}

.loader {
   margin: 30px auto;
   font-size: 10px;
   position: relative;
   text-indent: -9999em;
   border-top: 1.1em solid rgba(0, 128, 255, 0.2);
   border-right: 1.1em solid rgba(0, 128, 255, 0.2);
   border-bottom: 1.1em solid rgba(0, 128, 255, 0.2);
   border-left: 1.1em solid #0080ff;
   -webkit-transform: translateZ(0);
   -ms-transform: translateZ(0);
   transform: translateZ(0);
   -webkit-animation: load8 1.1s infinite linear;
   animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

@keyframes load8 {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

.breadcrumb {
   li {
      cursor: pointer;
   }
}

.section {
   padding: 5px 10px;
   background-color: #363535;
   color: #ffffff;
   margin-bottom: 15px;
}

table img {
   width: 40px;
   height: auto;
}

ngb-pagination {
   ul {
      margin: 0;
   }
}

.example-custom-placeholder {
   background-color: red;
}

.os-badge {
   width: 80px;
   display: block;
   margin: auto;
}

.front-preview {
   h5 {
      text-transform: uppercase;
      font-size: 18px;
      background-color: black;
      color: #fff;
      padding: 5px;
      margin-top: 10px;
   }

   h6 {
      margin: 0;
      color: red;
   }
}

.tab-content {
   padding: 20px 0;
}

.pm {
   &-online, &-offline {
      color: #ffffff;
      padding: 3px 10px;
      text-transform: uppercase;
      font-size: 12px;
      width: 100px;
      display: inline-block;
      text-align: center;
   }

   &-online {
      background-color: green;
   }

   &-offline {
      background-color: #ee7b3e;
   }
}

.ps {
   &-paid, &-pending, &-partial, &-failed, &-cancelled {
      color: #ffffff;
      text-align: center;
      padding: 3px 10px;
      text-transform: uppercase;
      font-size: 12px;
      width: 100px;
      display: inline-block;
   }

   &-paid {
      background-color: green;
   }

   &-pending {
      background-color: #ee7b3e;
   }

   &-partial {
      background-color: #b8b86f;
   }

   &-failed {
      background-color: red;
   }

   &-cancelled {
      background-color: #900303;
   }
}

a {
   cursor: pointer;

   &:hover {
      color: blue;
   }
}

.foreground-closing {
   -webkit-transition: all 0.2s !important;
   -moz-transition: all 0.2s !important;
   -ms-transition: all 0.2s !important;
   -o-transition: all 0.2s !important;
   transition: all 0.2s !important;
}

.tbl-status {
   padding: 3px 10px;
   text-transform: uppercase;
   text-align: center;
   display: block;
   color: #ffffff;
   font-size: 12px;
   font-weight: bold;
}

.status {
   &-partial {
      background-color: #d47500;
   }

   &-amount {
      background-color: #f7d5ff;
      color: darken(#d099db, 40);
   }

   &-percent {
      background-color: #c9feff;
      color: darken(#99d9db, 40);
   }

   &-publish, &-Publish, &-paid, &-success, &-active, &-true, &-published, &-1 {
      background-color: rgba(#58a203, 0.3);
   }

   &-draft {
      background-color: #ff9901;
   }

   &-cancelled, &-expired {
      background: #ff0000;
   }

   &-pending, &-failed, &-expired, &-inactive, &-false, &-0 {
      background-color: lighten(red, 40);
      color: darken(red, 10);
   }
}

.spaced-btns {
   white-space: nowrap;

   .btn {
      margin-right: 5px;
   }
}

.p-dialog {
   width: 100%;
   max-width: 600px;
}

td.min {
   width: 5%;
   white-space: nowrap;
}

.btn-w-100 {
   width: 90px;
}

.list-striped {
   li {
      padding: 3px;
      font-size: 14px;

      &:nth-child(2n) {
         background-color: darken(white, 5);
      }
   }
}

.xs-sidebar {
   position: fixed;
   top: 0;
   right: 0;
   height: 100%;
   z-index: 1000;
   background-color: #f6f6f6;
   width: 40%;
   -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
   -moz-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
   box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
   display: flex;
   flex-direction: column;
   transition: all 0.5s;
   -webkit-animation: cubic-bezier(.17, .67, .44, .98);
   -o-animation: cubic-bezier(.17, .67, .44, .98);
   animation: cubic-bezier(.17, .67, .44, .98);

   table {
      tr:nth-child(2n) {
         background-color: darken(white, 6);
      }

      td {
         padding: 4px;
      }
   }

   p {
      margin: 0;
      padding: 0;
   }

   ol {
      padding-left: 20px;
      margin-top: 10px;
      list-style-type: lower-alpha;
   }

   .content {
      flex: 1 1 auto;
      overflow: auto;
   }

   &.closed {
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -ms-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%);
   }

   header {
      padding: 10px;
      background-color: #fff;
      font-size: 18px;
      font-weight: bold;

      a {
         float: right;
         margin-right: 30px;
         color: red;
      }
   }
}

//test attempt
.status {
   background-color: #fff;
   padding: 3px 5px;
   font-size: 12px;
   text-transform: uppercase;
   color: #ffffff;
   width: 120px;
   display: inline-block;
   text-align: center;

   &-ended {
      background-color: green;
   }

   &-in-progress {
      background-color: #ea6f02;
   }
}

.test-attempt-answers {
   p {
      margin: 0;
   }
}

mwl-confirmation-popover-window {
   .popover-title {
      background-color: #fff;
      color: #000000;
      text-transform: uppercase;
      font-weight: bold;
   }

   .popover {
      border: 0px;
      padding: 15px;
      -webkit-box-shadow: 0px 0px 20px #cccccc;
      -moz-box-shadow: 0px 0px 20px #cccccc;
      box-shadow: 0px 0px 20px #cccccc;
   }
}

.p-calendar {
   &, input {
      display: block;
      width: 100%;
   }
}

.filters {
   input, select {
      height: auto;
      padding: 10px 0;
      border: 0px solid #e4e4e4;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      color: #000000 !important;
      box-shadow: none !important;
   }

   p-calendar {
      input {
         padding: 0 !important;
         border: 0 !important;
         font-size: 13px
      }
   }
}

.sub-menu {
   li a {
      @apply tw-px-4 tw-py-3 tw-text-white tw-flex tw-gap-4;
      :hover {
         @apply tw-bg-blue-100 tw-no-underline
      }
   }
}
